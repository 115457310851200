import ADDRESS_DATA from '../util/TumbonAddr.json'

const convertProvinceIdToText = (pid) => { let result = ADDRESS_DATA.filter(item => item.provinceid === pid); let data = result.length > 0 ? result[0].provincename : null; return data; }
const convertCityIdToText = (pid, cid) => { let result = ADDRESS_DATA.filter(item => item.provinceid === pid && item.cityid === cid); let data = result.length > 0 ? result[0].cityname : null; return data; }
const convertTumbonIdToText = (pid, cid, tid) => { let result = ADDRESS_DATA.filter(item => item.provinceid === pid && item.cityid === cid && item.tumbonid === tid); let data = result.length > 0 ? result[0].tumbonname : null; return data; }
const convertProvinceTextToId = (pname) => { let result = ADDRESS_DATA.filter(item => item.provincename === pname); let data = result.length > 0 ? result[0].provinceid : null; return data; }
const convertCityTextToId = (pname, cname) => { let result = ADDRESS_DATA.filter(item => item.provincename === pname && item.cityname === cname); let data = result.length > 0 ? result[0].cityid : null; return data; }
const convertTumbonTextToId = (pname, cname, tname) => { let result = ADDRESS_DATA.filter(item => item.provincename === pname && item.cityname === cname && item.tumbonname === tname); let data = result.length > 0 ? result[0].tumbonid : null; return data; }

const LAT_INIT = 13.136717
const LNG_INIT = 100.523186

function mongoToObjectProvince(data) {
  var features = []
  for (var idx = 0; idx < data.length; idx++) {
    if (data[idx].border[0].length > 2) {
      for (var i in data[idx].border) {
        let obj = {
          provinceid: data[idx].province_id,
          provincename: data[idx].province_name,
          lat: data[idx].Latitude,
          lng: data[idx].Longtitude,
          type: '',
          border: []
        }
        for (let j in data[idx].border[i]) {
          obj.type = 'Polygon'
          if (data[idx].border[i][0] && data[idx].border[i][1]) {
            let lat = 0, lng = 0
            if (data[idx].border[i][j][0] > data[idx].border[i][j][1]) {
              lat = data[idx].border[i][j][1]
              lng = data[idx].border[i][j][0]
            } else {
              lng = data[idx].border[i][j][1]
              lat = data[idx].border[i][j][0]
            }
            obj.border.push([lng, lat])
          }
        }
        features.push(obj)
      }
    } else {
      let obj = {
        provinceid: data[idx].province_id,
        provincename: data[idx].province_name,
        lat: data[idx].Latitude,
        lng: data[idx].Longtitude,
        type: '',
        border: []
      }
      for (var j in data[idx].border) {
        obj.type = 'Polygon'
        if (data[idx].border[j][0] && data[idx].border[j][1]) {
          let lat = 0, lng = 0
          if (data[idx].border[j][0] > data[idx].border[j][1]) {
            lat = data[idx].border[j][1]
            lng = data[idx].border[j][0]
          } else {
            lng = data[idx].border[j][1]
            lat = data[idx].border[j][0]
          }
          obj.border.push([lng, lat])
        }
      }
      features.push(obj)
    }
  }
  return features
}

function mongoToObjectCity(data) {
  var features = []
  for (var idx = 0; idx < data.length; idx++) {
    if (data[idx].border.length > 0) {
      if (data[idx].border[0].length > 2) {
        for (var i in data[idx].border) {
          let obj = {
            provinceid: data[idx].province_id,
            cityid: data[idx].city_id,
            cityname: data[idx].city_name,
            lat: data[idx].Latitude,
            lng: data[idx].Longtitude,
            type: '',
            border: []
          }
          for (let j in data[idx].border[i]) {
            obj.type = 'Polygon'
            if (data[idx].border[i][0] && data[idx].border[i][1]) {
              let lat = 0, lng = 0
              if (data[idx].border[i][j][0] > data[idx].border[i][j][1]) {
                lat = data[idx].border[i][j][1]
                lng = data[idx].border[i][j][0]
              } else {
                lng = data[idx].border[i][j][1]
                lat = data[idx].border[i][j][0]
              }
              obj.border.push([lng, lat])
            }
          }
          features.push(obj)
        }
      } else {
        let obj = {
          provinceid: data[idx].province_id,
          cityid: data[idx].city_id,
          cityname: data[idx].city_name,
          lat: data[idx].Latitude,
          lng: data[idx].Longtitude,
          type: '',
          border: []
        }
        for (var j in data[idx].border) {
          obj.type = 'Polygon'
          if (data[idx].border[j][0] && data[idx].border[j][1]) {
            let lat = 0, lng = 0
            if (data[idx].border[j][0] > data[idx].border[j][1]) {
              lat = data[idx].border[j][1]
              lng = data[idx].border[j][0]
            } else {
              lng = data[idx].border[j][1]
              lat = data[idx].border[j][0]
            }
            obj.border.push([lng, lat])
          }
        }
        features.push(obj)
      }
    }
  }
  return features
}

function mongoToObjectTumbon(data) {
  var features = []
  for (var idx = 0; idx < data.length; idx++) {
    if (data[idx].border.length > 0) {
      if (data[idx].border[0].length > 2) {
        for (var i in data[idx].border) {
          let obj = {
            provinceid: data[idx].province_id,
            cityid: data[idx].city_id,
            tumbonid: data[idx].tumbon_id,
            tumbonname: data[idx].tumbon_name,
            lat: data[idx].Latitude,
            lng: data[idx].Longtitude,
            type: '',
            border: []
          }
          for (let j in data[idx].border[i]) {
            obj.type = 'Polygon'
            if (data[idx].border[i][0] && data[idx].border[i][1]) {
              let lat = 0, lng = 0
              if (data[idx].border[i][j][0] > data[idx].border[i][j][1]) {
                lat = data[idx].border[i][j][1]
                lng = data[idx].border[i][j][0]
              } else {
                lng = data[idx].border[i][j][1]
                lat = data[idx].border[i][j][0]
              }
              obj.border.push([lng, lat])
            }
          }
          features.push(obj)
        }
      } else {
        let obj = {
          provinceid: data[idx].province_id,
          cityid: data[idx].city_id,
          tumbonid: data[idx].tumbon_id,
          tumbonname: data[idx].tumbon_name,
          lat: data[idx].Latitude,
          lng: data[idx].Longtitude,
          type: '',
          border: []
        }
        for (var j in data[idx].border) {
          obj.type = 'Polygon'
          if (data[idx].border[j][0] && data[idx].border[j][1]) {
            let lat = 0, lng = 0
            if (data[idx].border[j][0] > data[idx].border[j][1]) {
              lat = data[idx].border[j][1]
              lng = data[idx].border[j][0]
            } else {
              lng = data[idx].border[j][1]
              lat = data[idx].border[j][0]
            }
            obj.border.push([lng, lat])
          }
        }
        features.push(obj)
      }
    }
  }
  return features
}

function defaultLngLat() {
  return [LAT_INIT, LNG_INIT]
}

export {
  convertProvinceIdToText,
  convertCityIdToText,
  convertTumbonIdToText,
  convertProvinceTextToId,
  convertCityTextToId,
  convertTumbonTextToId,
  mongoToObjectProvince,
  mongoToObjectCity,
  mongoToObjectTumbon,
  defaultLngLat,
  LAT_INIT,
  LNG_INIT
}