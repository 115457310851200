import * as React from 'react';
import { navigate } from 'gatsby'
import {
  Drawer,
  Avatar,
  List,
  Divider
} from '@mui/material';

import LockOpenIcon from '@mui/icons-material/LockOpen';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Button from '@mui/material/Button';
import api from '../util/api';
import Icon_fund1 from '../../images/navicons/2.1 cct.svg'
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';



export default function NavDrawer({ lang, open, onClose, mainMenu, subMenu, userData, isChangeRoles, onOpenchangRole }) {
  const [userInfo, setUserInfo] = React.useState(null)
  React.useEffect(() => {
    let user = userData?.user
    let user_role = userData?.roles

    if (user ) {
      setUserInfo({ user: user, roles: user_role })
    }

  }, [userData])


  return (
    <Drawer
      open={open}
      onClose={onClose}
    >
      <List
        className='w-[90vw] sm:w-[80vw] md:w-[70vw] lg:w-[60vw] xl:w-[60vw] 2xl:w-[60vw]'
        // sx={{ bgcolor: 'whtie' }}
        component="nav"
        aria-labelledby="nested-list-subheader"
      >
        <>
          {
            userInfo ?
              <div>
                <div className='flex flex-rom p-4'>
                  <Avatar sx={{ backgroundColor: '#038967' }}>
                    {userInfo?.user[0].toUpperCase()}
                  </Avatar>
                  <p className="flex lg:hidden m-1 mr-auto text-xl text-center">{userInfo?.user}</p>
                </div>

                <div className='flex flex-row pl-4 mb-1'>
                  <Button variant="contained" className='m-2' style={{ margin: 2 }} onClick={() => { navigate('/editprofile') }} >Profile</Button>
                  {isChangeRoles && <Button variant="contained" style={{ margin: 2 }} onClick={() => { onOpenchangRole(true) }}>เปลี่ยน Role</Button>}
                  <Button variant="contained" className='m-2' style={{ margin: 2 }} onClick={() => { api.onLogout() }} >logout</Button>
                </div>

              </div>
              :
              <div className='flex flex-row pl-4 mb-1'>
                <Button variant="contained" startIcon={<LockOpenIcon />} onClick={() => { navigate('/login') }} sx={{ m: 1, width: 110 }}>Login</Button>
                <Button variant="outlined" startIcon={<PersonAddAltIcon />} onClick={() => { navigate('/register') }} sx={{ m: 1, width: 110 }} >Sign up</Button>
              </div>

          }

          <Divider style={{ backgroundColor: '#d1d5db' }} />

          {
            mainMenu.map((item) => {
              let list = []

              if (subMenu[item.id]) {
                list.push(<div className={"flex flex-row mx-4 mt-2 p-2 pb-0"}>
                  <p className='text-base text-[#9f9f9f] m-0 p-0'>{lang === 'EN' ? item.nameEN : item.name}</p>
                </div>)
                subMenu[item.id]
                  .map((element) => {
                    if (element.content.length === 0) {
                      list.push(<div className={"flex flex-row mx-4 mt-2 p-2 pb-0"}>
                        {
                          element?.icon ?
                            <img alt={`description`} src={element.icon} width='20px' height='20px'></img> :
                            <LockOpenIcon style={{ color: '#048968' }} />
                        }
                        <a href={element.url ? element.url : '#'} className='ml-2 text-base text-[#6c737f] m-0 p-0'> {lang === 'EN' ? element.nameEN : element.name} {element.inOrganization ? <span className='text-sm text-gray-500'>(ใช้เฉพาะภายใน)</span> : <></>} </a>
                      </div>)
                      list.push(<div className='w-full mx-4 mt-2 h-[1px] bg-slate-200'></div>)
                    } else {
                      list.push(<div className={"flex flex-row mx-4 mt-2 p-2 pb-0"}>
                        <img alt={`description`} src={element.icon} width='20px' height='20px'></img>
                        <p className='ml-2 text-base text-[#6c737f] m-0 p-0'>{lang === 'EN' ? element.nameEN : element.name} {element.inOrganization ? <span className='text-sm text-gray-500'>(ใช้เฉพาะภายใน)</span> : <></>}</p>
                        <div className='flex flex-1' />
                        <ArrowDropDownIcon fontSize="small" />
                      </div>)
                      list.push(<div className='w-full mx-4 mt-2 h-[1px] bg-slate-200'></div>)
                      element.content.map(content => {
                        list.push(<div className=' ml-6 flex flex-row items-center'>
                          <div className='w-[20px] h-[20px] flex items-center justify-center'>
                            <div className={
                              window.location.pathname.includes(content?.link) ?
                                'w-[6px] h-[6px] bg-green-400 rounded-full' :
                                'w-[6px] h-[6px] bg-white rounded-full'
                            }>
                            </div>
                          </div>
                          <a href={content.link ? content.link : '#'} className='ml-2 my-2 font-light text-sm text-[#6c737f] m-0 p-0'>{lang === 'EN' ? content.textEN : content.text}</a>
                        </div>)
                      })

                    }
                  })
              } else {
                list.push(<div className={"flex flex-row mx-4 mt-2 p-2 pb-0"}>
                  <img alt={`description`} src={Icon_fund1} width='20px' height='20px'></img>
                  <a href={item.url ? item.url : '#'} className='ml-2 text-base text-[#6c737f] m-0 p-0'>{lang === 'EN' ? item.nameEN : item.name}</a>
                </div>)
              }

              return (
                <div className='flex flex-col'>
                  {list}
                </div>
              )
            })
          }
        </>
      </List>
    </Drawer>
  )
}

