import * as React from 'react';
import { navigate } from 'gatsby'
import {
  Button,
  Fade,
  Box,
  Popper,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Tooltip,
  IconButton,
  Menu,
  MenuItem,
  Divider,
  ListItemIcon,
  Typography
} from '@mui/material';
import { Popover, Modal } from 'antd';
import api from '../util/api';
import styled from 'styled-components'
import Logo from '../../images_webp/logo/iseelogosmall.webp'
import Logout from '@mui/icons-material/Logout';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import TH from '../../images_webp/F_TH.webp'
import EN from '../../images_webp/F_EN.webp'

const ButtonMemu = styled.a`
  color: #fff;
  justify-self :center;
  background-color: rgba(0,0,0,0);
  border: 0;
  font-family: Kanit;
  font-size: 18px;
  align-items: center;
  display: flex;
  padding: 12px 8px 12px 8px;
  &:hover {
    color: #3CC44F;
  }
`

const PopperArrow = styled(Popper)`
  position: relative;
  &:before {
    content: "";
    display: block;
    position: absolute;
    right: 45%;
    top: -15px;
    border-bottom: 15px solid #fff;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
  }
`

export default function SimplePopper({ lang, closeD, isShowChangeLang, mainMenu, subMenu, userData, isChangeRoles, changeLang, onOpenchangRole }) {
  const [isShowS, setShowS] = React.useState(false)
  const [isLogin, setLogin] = React.useState(false)
  const [imgProfile, setImageProfile] = React.useState(null)
  const [userInfo, setUserInfo] = React.useState(null)
  const [openSubmenu, setOpenSubMenu] = React.useState(false)
  const [subMenuID, setSubMenuID] = React.useState('')
  const [open_usermenu, setOpenUserMenu] = React.useState(false)
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [currentUrl, setCurrentUrl] = React.useState(null);

  React.useEffect(() => {
    let user = userData?.user
    let user_role = userData?.roles
    let image = localStorage.getItem('img_profile')
    if (user) {
      setLogin(true)
      setUserInfo({ user: user, name: user, roles: user_role })
      if (image) setImageProfile(image)
    }
    if (window?.location?.pathname) {
      setCurrentUrl(window?.location?.pathname)
      if (window.location.pathname === "/target_resources") {
        setShowS(false)
      }
    }
  }, [userData])

  const handlePopoverOpen = (event) => {
    if (event.currentTarget.id === 'account-menu') {
      setOpenSubMenu(false)
      setOpenUserMenu(true)
    } else {
      setSubMenuID(event.currentTarget.id)
      setOpenSubMenu(true)
      setOpenUserMenu(false)
    }
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setOpenSubMenu(false)
    setOpenUserMenu(false)
    setAnchorEl(null);
  };

  const ContentSubMenu = ({ menu }) => {
    return menu ? (
      <List dense={true} className='w-[100%] max-w-[600px] bg-white rounded-xl' style={{ padding: '1rem' }}>
        {
          menu.map((item, idx) => {
            let highlight = ''
            if (item.content.length > 0) {
              // console.log(currentUrl,item,(item.content.filter(tmp => tmp.link === currentUrl).length > 0))
              if (item.content.filter(tmp => tmp.link?.replaceAll('/', '') === currentUrl?.replaceAll('/', '')).length > 0) highlight = 'text-green-600'
            } else {
              if (item.url?.replaceAll('/', '') === currentUrl?.replaceAll('/', '')) highlight = 'text-green-600'
            }
            return (
              <div>
                <ListItem /*button component="a" href={!item.url ? '#' : item.url}*/ alignItems="flex-start">
                  {
                    item.icon && <ListItemAvatar className='cursor-pointer mb-auto' onClick={() => navigate(item.url)}>
                      <img alt={`description`} src={item.icon} width='30px' height='30px'></img>
                    </ListItemAvatar>
                  }
                  <ListItemText
                    primary={
                      // <a className='cursor-pointer no-underline hover:underline text-lg' onClick={() => navigate(item.url)}>
                      //   {item.name}
                      // </a>
                      <a className={`${highlight} cursor-pointer no-underline hover:underline text-lg text-inherit my-auto`} href={!item.url ? '#' : item.url}>
                        {lang === 'EN' ? item.nameEN : item.name} {item.inOrganization ? <span className='text-sm text-gray-500'>(ใช้เฉพาะภายใน)</span> : <></>}
                      </a>
                    }
                    secondary={
                      item.content.length !== 0 ?
                        <Box>
                          <div className="grid grid-cols-2 gap-x-2">
                            {
                              item.content.map(element => {
                                return <ListItem sx={{ pl: 0 }}>
                                  <ListItemText secondary={<Typography className='cursor-pointer no-underline hover:underline' onClick={() => navigate(element.link)}>
                                    <span className='text-sm'>{lang === 'EN' ? element.textEN : element.text}</span>
                                  </Typography>}
                                  />
                                </ListItem>
                              })
                            }
                          </div>
                        </Box> : ''
                    } />
                </ListItem>
                {idx === menu.length - 1 ? <></> : <Divider />}
              </div>
            )
          })
        }
      </List>
    ) : <></>
  }

  const menuUser = () => {
    return (
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open_usermenu}
        onClose={handlePopoverClose}
        onClick={handlePopoverClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem onClick={() => navigate('/editprofile')}>
          <Avatar /> Profile
        </MenuItem>
        <Divider />
        {
          isChangeRoles ?
            <><MenuItem onClick={() => onOpenchangRole(true)}>
              <ListItemIcon>
                <AdminPanelSettingsIcon />
              </ListItemIcon>
              เปลี่ยน Role
            </MenuItem>
              <Divider />
            </> : <></>
        }
        {
          userInfo.roles.includes('admin') ?
            <><MenuItem onClick={() => navigate('/admin-management')}>
              <ListItemIcon>
                <AdminPanelSettingsIcon />
              </ListItemIcon>
              จัดการผู้ใช้งาน
            </MenuItem>
              <Divider />
            </> : <></>
        }
        <MenuItem onClick={() => api.onLogout()}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
    )
  }

  return (
    <div style={{
      position: 'fixed',
      zIndex: 1,
      height: isShowS ? 150 : 80,
      width: '100%',
      alignItems: 'center',
      backgroundColor: 'rgba(0, 0, 0, 0.4)',
      boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px',
      backdropFilter: 'blur(8px)',
      flexDirection: 'column',
      display: 'flex',
      zIndex: '10'
    }}>
      <div className='max-w-[1300px] 2xl:max-w-[1400px] w-full flex flex-row h-[80px] px-4 xl:px-0 justify-start'>
        <a href="/">
          <img alt={`description`} src={Logo} width='72px' height='72px'></img>
        </a>
        <div className='w-8'></div>
        <div className='hidden lg:flex lg:flex-1 lg:flex-row justify-end' onMouseLeave={handlePopoverClose}>
          {
            mainMenu.map((element, idx) => {
              let highlight = ''
              if (element.url) {
                if (element.url?.replaceAll('/', '') === currentUrl?.replaceAll('/', '')) highlight = 'text-green-400'
                return <ButtonMemu key={'btnmenu-' + idx} href={element.url} onMouseEnter={handlePopoverClose}>
                  <span className={`${highlight}`}>{lang === 'EN' ? element?.nameEN : element.name}</span>
                </ButtonMemu>
              } else {
                if (subMenu[element.id]) {
                  subMenu[element.id].map((item) => {
                    if (item.content.length > 0) {
                      // console.log(currentUrl,item,(item.content.filter(tmp => tmp.link === currentUrl).length > 0))
                      if (item.content.filter(tmp => tmp.link?.replaceAll('/', '') === currentUrl?.replaceAll('/', '')).length > 0) highlight = 'text-green-400'
                    } else {
                      if (item.url?.replaceAll('/', '') === currentUrl?.replaceAll('/', '')) highlight = 'text-green-400'
                    }
                  })
                }
                return <ButtonMemu
                  key={'btnmenu-' + idx}
                  id={element.id}
                  variant="contained"
                  aria-owns={openSubmenu ? 'mouse-over-popover' : undefined}
                  aria-haspopup="true"
                  onMouseEnter={handlePopoverOpen}>
                  <span className={`${highlight}`}>{lang === 'EN' ? element?.nameEN : element.name}</span>
                </ButtonMemu>
              }
            })
          }

          <PopperArrow
            id={openSubmenu ? 'simple-popper' : undefined}
            open={openSubmenu}
            anchorEl={anchorEl}
            placement="bottom"
            style={{
              zIndex: 999,
              boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
              borderRadius: 12
            }}>
            <div>
              <ContentSubMenu menu={subMenu[subMenuID]} />
            </div>
          </PopperArrow>

        </div>
        <div className='flex flex-1 lg:hidden'></div>
        {
          isShowChangeLang &&
          // <div className='hidden lg:flex my-auto justify-center'>
          <div className='flex my-auto justify-center'>
            <Popover
              placement="bottom"
              title={null}
              content={<div style={{ padding: 0, margin: 0 }}>
                <div>
                  <a className='text-black hover:text-green-800' onClick={() => {
                    localStorage.setItem('lang', 'TH')
                    changeLang('TH')
                  }}>ไทย</a>
                </div>

                <div>
                  <a className='text-black hover:text-green-800' onClick={() => {
                    localStorage.setItem('lang', 'EN')
                    changeLang('EN')
                  }}>English</a>
                </div>
              </div>}
            >
              <div>
                <img alt={`description`} src={lang === 'EN' ? EN : TH} width='30px' height='30px' style={{ borderRadius: '4px' }} ></img>
              </div>
            </Popover>
          </div>

        }
        <div className='flex lg:hidden h-full mx-5' onClick={() => { closeD() }}>
          <svg className="h-8 w-8 text-500" fill="none" viewBox="0 0 24 24" stroke="currentColor" style={{ display: 'flex', alignSelf: 'center', }}>
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
          </svg>
        </div>
        {
          (isLogin && userInfo) ?
            <div className='hidden lg:flex my-auto justify-center'>
              <Tooltip title={`(${userInfo.user})`}>
                <IconButton
                  id='account-menu'
                  onClick={handlePopoverOpen}
                  size="small"
                  aria-controls={open_usermenu ? 'account-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={open_usermenu ? 'true' : undefined}>
                  {
                    imgProfile ? <Avatar alt={userInfo.user[0].toUpperCase()} src={imgProfile} /> :
                      <Avatar sx={{ backgroundColor: '#038967' }}>
                        {userInfo.user[0].toUpperCase()}
                      </Avatar>
                  }
                </IconButton>
              </Tooltip>
              {menuUser()}
            </div>
            :
            <div className='hidden lg:flex my-auto ml-4 justify-center'>
              <Button href="/login" variant="contained" sx={{ backgroundColor: '3ECC4E' }}>{lang == 'EN' ? 'Login' : 'เข้าสู่ระบบ'}</Button>
            </div>
        }
      </div >
    </div >
  )
}
