import * as React from 'react';
import Logoeef from '/images/logo/logo-eef-vertical@2x.png'
export default function Footerapp({ subMenu,isHideMenu = false, isScreenSizeGL = false, translate, lang = 'TH' }) {

  const [sub_menu,setSubMenu] = React.useState(subMenu)

  React.useEffect(() => {
    setSubMenu(subMenu)
  },[subMenu])

  const TextList = ({id}) => {
    if(!sub_menu[id]) return <></>
    let ul = sub_menu[id].map(ele => {
      return <ul style={{ listStyleType: 'disc', paddingLeft: '8px' }}>
        <li className='text-white ml-[8px]'>
          <a className='text-white' href={ele.url}>
            {lang === 'TH' ? ele.name : ele.nameEN}
          </a>
        </li>
        {ele.content.length > 0 && 
        <ul style={{ listStyleType: 'circle', paddingLeft: '8px' }}>
          {
            ele.content.map(sub =>           
              <li className='text-white ml-[8px]'>
                <a className='text-white' href={sub.link}>
                  {lang === 'TH' ? sub.text : sub.textEN}
                </a>
              </li>
            )
          }
        </ul>
        }
      </ul>
    })
    return ul
  }

  if(Array.isArray(sub_menu)) return <></>
  return (
    <div className='w-full bg-[#3D4F66]'>
      <div className="max-w-[1300px] 2xl:max-w-[1400px] lg:min-h-[450px] rounded-3xl m-auto bordered py-4 px-2">'
        <div className="grid grid-cols-10 gap-2 w-full mt-6 lg:mt-8">
          <div className="col-span-10 lg:col-span-12">
            <div className='flex flex-row'>
              <div className='flex flex-col ml-2 justify-between'>
                <div className='text-white text-md lg:text-2xl'> {translate?.EEF_FULL}</div>
                <div className='text-white text-sm lg:text-lg'> {translate?.adddress_1}</div>
                <div className='text-white text-sm lg:text-lg'> {translate?.adddress_2}</div>
              </div>
            </div>
          </div>
        </div>
        <div className="grid grid-cols-10 gap-2 w-full mt-6 bg-white h-[1px]">
        </div>
        <div className="hidden md:grid grid-cols-10 gap-2 w-full mt-2 ">
          <div className="col-span-10 lg:col-span-2">
            <div className='flex flex-col'>
              <div className='text-white text-lg'> {translate?.MENU_Home}</div>
              <TextList id="index" /> 
            </div>
            {sub_menu["sub_menu_database"].length > 0 && sub_menu["sub_menu_target"].length > 0 &&  
              <div className='flex flex-col'>
                <div className='text-white text-lg'> {translate?.MENU_Target}</div>
                <TextList id="sub_menu_target" /> 
              </div>
            }
          </div>
          {sub_menu["sub_menu_database"].length === 0 && sub_menu["sub_menu_target"].length > 0 &&  
            <div className="col-span-10 lg:col-span-2">
              <div className='flex flex-col'>
                <div className='text-white text-lg'> {translate?.MENU_Target}</div>
                <TextList id="sub_menu_target" /> 
              </div>
            </div>
          }
          {sub_menu["sub_menu_fund"].length > 0 &&  
            <div className="col-span-10 lg:col-span-2">
              <div className='flex flex-col'>
                <div className='text-white text-lg'> {translate?.MENU_Fund}</div>
                <TextList id="sub_menu_fund" /> 
              </div>
            </div>
          }
          {sub_menu["sub_menu_innovation"].length > 0 &&  
            <div className="col-span-10 lg:col-span-2">
              <div className='flex flex-col'>
                <div className='text-white text-lg'> {translate?.MENU_Innovation}</div>
                <TextList id="sub_menu_innovation" /> 
              </div>
            </div>
          }
          {sub_menu["sub_menu_research"].length > 0 &&  
           <div className="col-span-10 lg:col-span-2 ">
            <div className='flex flex-col'>
              <div className='text-white text-lg'> {translate?.MENU_Research}</div>
              <TextList id="sub_menu_research" /> 
            </div>
          </div>
          }
          {sub_menu["sub_menu_database"].length > 0 &&  
            <div className="col-span-10 lg:col-span-2">
              <div className='flex flex-col'>
                <div className='text-white text-lg'> ฐานข้อมูล กสศ.</div>
                <TextList id="sub_menu_database" /> 
              </div>
            </div>
          }
        </div>
      </div>
    </div>
  );
}
