import React, { useState, useEffect } from 'react';
import api from '../util/api';

import { Modal, Button } from "antd";
import {
  FormControl, InputLabel, Select, MenuItem
} from '@mui/material';

import Zones from "../templates/election_map/model/zone_ss_isee.json";
import TumbonAddr from '../util/TumbonAddr.json'
import {
  convertProvinceTextToId,
} from '../mapbox/mapboxfn'
import Swal from 'sweetalert2';
import { navigate } from 'gatsby';

function validateRequire(text) {
  if (text) {
    return null
  } else {
    return `ต้องกรอกช่องนี้`
  }
}

const PageAdmin = (props) => {
  let { roles } = props
  const [validate_field, setValidateField] = useState({
    provinceId: [],
    zoneId: [],
    roles: [],
  })
  const [user_data, setUserData] = React.useState({
    provinceId: null,
    zoneId: null,
    roles: null,
  })
  const [list_zones, setListZone] = useState([])

  const MAP_ROLE_TEXT = {
    eef: 'กสศ.',
    partner: 'หน่วยงานภาคี',
    representative: 'สส.',
    admin: 'ผู้ดูแลระบบ',
    partner_province: 'ภาคีจังหวัด'
  }
  useEffect(async () => {
    let list = [...new Set(TumbonAddr.map(item => item.provincename))].sort().map(item => { return { province_id: convertProvinceTextToId(item), province_name: item, zone: [] } })
    list = list.map(item => {
      if (Zones.filter(res => res.provinceId.toString() === item.province_id).length > 0)
        item.zone = Zones.filter(res => res.provinceId.toString() === item.province_id).map(res => res.no).sort()
      return item
    })
    setListZone(list)
  }, [])

  const onSaveChangeRole = async () => {
    const response = await api.changeUserRole(user_data)
    if (response.ok) {
      if (window.location.pathname === '/') {
        window.location.reload()
      } else {
        navigate('/', { replace: true })
      }
    } else {
      Swal.fire('', 'ไม่สามารถเปลี่ยน Role ได้', 'error')
    }
  }

  return (
    <Modal
      {...props}
      title={<span className="text-2xl font-bold">เปลี่ยนบทบาท</span>}
      okButtonProps={{ style: { display: "none" } }}
      cancelButtonProps={{ style: { display: "none" } }}
      footer={[
        <Button
          onClick={() => onSaveChangeRole()}
          variant="contained"
          sx={{ backgroundColor: "3ECC4E" }}
        >
          บันทึก
        </Button>,
      ]}
    >
      <div className='mt-4'>
        <div className='grid grid-cols-6 gap-2'>
          <FormControl className="col-span-6" fullWidth margin="normal" size="small">
            <InputLabel id="roles-select-small">บทบาท</InputLabel>
            <Select
              id="roles"
              name="roles"
              label="บทบาท"
              onChange={(e) => setUserData({ ...user_data, roles: e.target.value })}
              value={user_data.roles ? user_data.roles : ''}
              error={validate_field.roles.filter(item => item !== null).length > 0}
              helperText={validate_field.roles.filter(item => item !== null).length > 0 ? validate_field.roles.filter(item => item !== null)[0] : ''}>
              {
                roles.map(ele =>
                  <MenuItem value={ele}>{MAP_ROLE_TEXT[ele]}</MenuItem>
                )
              }
            </Select>
          </FormControl>
          {
            user_data.roles?.includes('partner_province') &&
            <FormControl className="col-span-3" fullWidth margin="normal" size="small">
              <InputLabel id="roles-select-small">จังหวัด</InputLabel>
              <Select
                id="provinceId"
                name="provinceId"
                label="จังหวัด"
                onChange={(e) => setUserData({ ...user_data, provinceId: e.target.value })}
                value={user_data.provinceId ? user_data.provinceId : ''}
                error={validate_field.provinceId.filter(item => item !== null).length > 0}
                helperText={validate_field.provinceId.filter(item => item !== null).length > 0 ? validate_field.provinceId.filter(item => item !== null)[0] : ''}>
                {
                  list_zones.map(item => {
                    return <MenuItem value={item.province_id}>{item.province_name}</MenuItem>
                  })
                }
              </Select>
            </FormControl>
          }
          {
            user_data.roles?.includes('representative') &&
            <>
              <FormControl className="col-span-3" fullWidth margin="normal" size="small">
                <InputLabel id="roles-select-small">จังหวัด</InputLabel>
                <Select
                  id="provinceId"
                  name="provinceId"
                  label="จังหวัด"
                  onChange={(e) => setUserData({ ...user_data, provinceId: e.target.value, zoneId: null })}
                  value={user_data.provinceId ? user_data.provinceId : ''}
                  error={validate_field.provinceId.filter(item => item !== null).length > 0}
                  helperText={validate_field.provinceId.filter(item => item !== null).length > 0 ? validate_field.provinceId.filter(item => item !== null)[0] : ''}>
                  {
                    list_zones.map(item => {
                      return <MenuItem value={item.province_id}>{item.province_name}</MenuItem>
                    })
                  }
                </Select>
              </FormControl>
              <FormControl className="col-span-3" fullWidth margin="normal" size="small">
                <InputLabel id="roles-select-small">เขตพื้นที่</InputLabel>
                <Select
                  id="zoneId"
                  name="zoneId"
                  label="เขตพื้นที่"
                  onChange={(e) => setUserData({ ...user_data, zoneId: e.target.value })}
                  value={user_data.zoneId ? user_data.zoneId : ''}
                  error={validate_field.zoneId.filter(item => item !== null).length > 0}
                  helperText={validate_field.zoneId.filter(item => item !== null).length > 0 ? validate_field.zoneId.filter(item => item !== null)[0] : ''}>
                  {
                    user_data.provinceId ? list_zones.filter(item => item.province_id === user_data.provinceId).length > 0 ?
                      list_zones.filter(item => item.province_id === user_data.provinceId)[0].zone.map(item => {
                        return <MenuItem value={item}>{`เขต ${item}`}</MenuItem>
                      }) : <></> : <></>
                  }
                </Select>
              </FormControl>
            </>
          }
        </div>
      </div>
    </Modal>
  )
}

export default PageAdmin



